.b_prof_logo_slider {
    background: #ffffff;
    @include padding(75);
}

.b_prof_logo_slider__content {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    height: 150px;
    margin: 10px auto;
    max-width: 100%;
    padding: 15px;
    box-shadow: 0px 7px 15px 0px rgba(0,0,0,0.1);

}

.b_prof_logo_slider__img {
    max-width: 100%;
    display: block;
}
