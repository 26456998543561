.b_btn {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    border: 1px solid #000;
    color: #fff;
    padding: 0 35px;
    font-size: 14px;
    margin: 15px 15px 15px 0;
    background: #000;
    text-align: center;
    transition: all .4s;
    @include xs {
        margin: 15px auto;
        text-align: center;
        width: 100%;
        max-width: 300px;
    }
    &:hover,
    &:focus,
    &:active {
        background: #aaa;
        color: #000;
        text-decoration: none;
    }
    //color modifications
    &--red {
        background: red;
    }
    //layout modifications
    &--center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 180px;
    }
    &--right {
        display: block;
        margin-left: auto;
        margin-right: 0;
        max-width: 180px;
    }
    //special modifications
    &--submit {}
}
