.b_content_area {
    background-color: #fff;
    padding: 70px 95px;
    margin-top: 48px;
    margin-bottom: 48px;

    @include md {
        padding: 30px 40px;
    }

    @include xs {
        padding: 30px 25px;
    }
    &--contact {
        padding-bottom: 0px;
    }
}

.b_content_area__title {
    margin-top: 0;
}

.b_content_area__sub_title {
    position: relative;
    @extend %bottom_border;

    display: block;
    font-family: 'open_sansregular';
    color: #000000;
    font-size: 18px;
    line-height: 1.7em;
}

// Client Editable
.b_content_area {
    h2, h3, h4, h5, h6 {
        @extend %bottom_border;
    }
    h2 {
        font-family: montserratregular;
        color: #6b9cf7;
        font-size: 24px;
        line-height: 50px;
        margin-bottom: 25px;
        margin-top: 30px;
        clear: both;
    }
    h3 {
        font-family: montserratregular;
        color: #6b9cf7;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 25px;
        margin-top: 30px;
        clear: both;
    }
    .play-div {
        margin-bottom: 40px;
    }
    .video-right {
        @include sm {
            h2, h3, h4, h5, h6 {
                margin-top: 10px;
            }
        }
    }
}
