.b_mmenu {
    &__mobile_btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        background-color: $color-secondary;
        padding: 10px;
        border-radius: 50%;
        float: right;
        transition: background-color .2s ease;
        .mm-opened & {
            background-color: $color-primary;
            span:nth-of-type(1) {
                opacity: 0;
            }
            span:nth-of-type(2) {
                transform: rotate(45deg);
            }
            span:nth-of-type(3) {
                transform: rotate(-45deg);
                bottom: 6px;
            }
        }
        span {
            transition: all .4s;
            position: relative;
        }
    }
    &__mobile_btn_element {
        display: block;
        margin: 2px 0;
        background: #fff;
        width: 20px;
        height: 2px;
    }
}

//******************* CUSTOMIZATION
$background: $color-primary;
$link-activ-background: $color-secondary;
$link-hover-background: $color-secondary;
$arrows: #fff;
$close-button-color: #fff;
$link-text-color: #fff;
$link-active-text-color: #fff;
$link-hover-text-color: #fff;
#body {
    .mm-menu {
        background: $background;
    }
    .mm-listview > li > a,
    .mm-listview > li > span {
        color: $link-text-color;
    }
    .mm-menu .mm-navbar > *,
    .mm-menu .mm-navbar a {
        color: $link-text-color;
    }
    .mm-prev:before {
        border-color: $arrows;
    }
    .mm-menu .mm-listview > li .mm-next:after,
    .mm-menu .mm-listview > li .mm-arrow:after {
        border-color: $arrows;
    }
    .mm-listview .mm-next {
        width: 100%;
    }
    .mm-listview > li {
        transition: all 1.8s;
        &:hover,
        &[class*="active"] {
            transition: all .4s;
            padding-left: 20px;
            background-color: $link-hover-background;
            a {
                color: $link-hover-text-color;
            }
            @include xs {
                padding-left: 0;
            }
        }
        &.active {
            background-color: $link-activ-background;
            a {
                color: $link-active-text-color;
            }
        }
    }
    #mm-0 .mm-title {
        display: none;
    }
    .mm-title {
        text-transform: uppercase;
    }
    #mobile-header #mmenu-btn {
        background: $background;
        cursor: pointer;
        &:hover {
            background: $link-hover-background;
        }
    }
    #close-btn {
        cursor: pointer;
        margin: 20px auto 0 auto;
        width: 30px;
        color: $close-button-color;
        @include xs {
            margin-top: -3px;
        }
    }
    #mobile-header {
        background: red;
    }
    #top-info {
        position: initial;
    }
    .mm-menu.mm-front {
        z-index: 15;
    }
    .mm-navbar-size-2 {
        @include xsmm {
            height: 65px;
        }
    }
}

//content customization
#body .mobile-callback .head_logo img {
    position: relative;
    bottom: 4px;
}

#close-btn {
    position: relative;
    transform: rotate(45deg);
    font-size: 32px;
    font-family: 'montserratbold';
}

html.mm-blocking {
    overflow-y: scroll !important;
}

.mm-panels .mm-panel:nth-child(1) .mm-title {
    display: none;
}
