.b_social {
    &--header {
        text-align: right;
    }
}

.b_social__wrapper {
    opacity: 0;
    transition: all .4s;
    &--visible {
        opacity: 1;
    }
}

.b_social__link {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 5px 2px;
    position: relative;
    left: 0;
}

.b_social__logo {
    & path:first-child {
        fill: #fff !important;
    }
    &:hover path:first-child {
        fill: $color-secondary !important;
        transition: fill .2s ease;
    }
    & path {
        fill: $color-primary !important;
    }
    .b_social--footer & {
        & path:first-child {
            fill: #fff;
        }
        &:hover path:first-child {
            fill: #4867a2 !important;
            transition: fill .2s ease;
        }
        & path {
            fill: #4867a2 !important;
        }
        &:hover path {
            fill: #fff !important;

        }
    }
    &--soci_facebook_button {
    }
    &--soci_twitter_button {
    }
    &--soci_linkedin_button {
    }
    &--soci_youtube_button {
    }
    &--soci_googleplus_button {
    }
    &--soci_pinterest_button {
    }
    &--soci_instagram_button {
    }
}
