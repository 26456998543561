.b_single_staff {
    @include padding;
}

.b_single_staff__img {
    img {
        @include img_border(#eab92e);
        max-width: 100%;
    }
}

.b_single_staff__row {
    display: flex;
    align-items: center;

    @include sm {
        flex-direction: column;
    }
}

.b_single_staff__bio {
    @include smu {
        width: 400px;
    }

    max-width: 100%;
    margin: 0 auto;
    text-align: center;
}

.b_single_staff__name {

    @extend %home_page_header;
    @extend %bottom_border_thin;

    font-family: 'open_sanssemibold';
    font-size: 24px;
    line-height: 1.7em;
    color: #000000;

    @include smu {
        margin-top: 0;
    }
}

.b_single_staff__desc {
    line-height: 31px;
    color: #837e85;
}

.b_single_staff__title {
    font-family: 'open_sansregular';
    font-size: 15px;
    line-height: 31px;
    color: #eab82c;
    display: block;
}

.b_single_staff__img {
    text-align: center;
}