$b_header__row--top_DEFAULT_HEIGHT: 122px !default;
$b_header__row--bottom_DEFAULT_HEIGHT: 75px !default;
$b_header_HEIGHT: $b_header__row--top_DEFAULT_HEIGHT + $b_header__row--bottom_DEFAULT_HEIGHT !default;
$b_header__row--top_TABLET_HEIGHT: 97px !default;
$b_header_PHONE_HEIGHT: 67px !default;

.b_wrapper {
	margin-top: $b_header_HEIGHT;

	@include md {
		margin-top: $b_header__row--top_TABLET_HEIGHT;
	}

	@include xs {
		margin-top: $b_header_PHONE_HEIGHT;
	}
	&--subpage {}
	&--contact_us {}
}

.b_wrapper__content_row {
	background-color: #ecf0f3;
}