

//+++++++++++++++++++++++
// Media Queries
//+++++++++++++++++++++++

@mixin xsmm {
    @media only screen and (max-width: 320px){
        @content;
    }
}
@mixin xsm {
    @media only screen and (max-width: 375px){
        @content;
    }
}

@mixin xs {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin xsu {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin sm {
    @media only screen and (max-width: 991px) {
        @content;
    }
}

@mixin smo {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
        @content;
    }
}

@mixin smu {
    @media only screen and (min-width: 992px) {
        @content;
    }
}

@mixin md {
    @media only screen and (max-width: 1199px) {
        @content;
    }
}

@mixin mdo {
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
        @content;
    }
}

@mixin lg {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

@mixin xlg {
    @media only screen and (min-width: 1920px) {
        @content;
    }
}

//+++++++++++++++++++++++
// Section Paddings
//+++++++++++++++++++++++
@mixin padding($percent: 100) {
    padding: ((50px * $percent) / 100) 0;
    @include md {
        padding: ((50px * $percent) / 100) 0;
    }
    @include sm {
        padding: ((60px * $percent) / 100) 0 ;
    }
    @include xs {
        padding: ((40px * $percent) / 100) 0;
    }
}

//+++++++++++++++++++++++
// Browser Hacks
//+++++++++++++++++++++++
@mixin br-firefox {
    @-moz-document url-prefix() {
        @content;
    }
}

@mixin br-edge {
    @supports (-ms-accelerator: true) {
        @content;
    }
}

@mixin br-ie10 {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

//+++++++++++++++++++++++
// Images
//+++++++++++++++++++++++
@mixin img($name) {
    background: url(/assets/images/#{$name}) no-repeat;
}

@mixin timg($name) {
    background: url(/templates/#{$template_group}/#{$template_theme}/assets/images/#{$name}) no-repeat;
}



//+++++++++++++++++++++++
// Custom
//+++++++++++++++++++++++

@mixin img_border($color: #dfe1e7) {
    border: 50px solid $color;
    padding: 13px;
    background-color: #fff;

    @include xs {
        border: 15px solid $color;
    }
}