.b_phone {
    display: block;
    color: #6b9cf7;
    font-family: 'montserratregular';
    font-size: 24px;
    transition: color .2s ease;
    text-decoration: none;

    &--header {
        margin-bottom: 12px;
        color: #6b9cf7;
        @include md {
            margin-bottom: 2px;
        }
    }

    &--map {
        font-family: 'open_sansregular';
        line-height: 26px;
        font-size: 14px;
        display: inline;
        color: #000000;

        @include sm {
            font-size: 20px;
        }

        @include xs {
            font-size: 16px;
        }
    }

    &--footer {

    }

    &:hover, &:active, &:focus {
        color: #eab82c;
        text-decoration: none;
    }
}