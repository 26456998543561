.b_contact {
    @include timg('b_contact__bg.jpg');
    @include padding(140);
    background-position: center;
    background-size: cover;

    @include xs {
        @include padding;
    }
}

.b_contact__form {
    width: 855px;
    max-width: 100%;
    margin: 0 auto;
}

.b_contact__title {
    @extend %home_page_header;
    @extend %bottom_border_thin;
    color: #fff;
    margin-top: 0;
    margin-bottom: 60px;

    &:after {
        background-color: #fff;
    }
}

.b_contact__input {
    margin-bottom: 60px;

    &--name_icon {
        @include timg('contact_name_icon.png');
        background-position-y: 5px;
    }
    &--email_icon {
        @include timg('contact_email_icon.png');
        background-position-y: 5px;
    }
    &--phone_icon {
        @include timg('contact_phone_icon.png');
        background-position-y: 5px;
    }

}

.b_contact__input_el {
    font-family: 'open_sansbold';
    font-size: 14px;
    line-height: 31px;
    color: #fff;
    background-color: transparent;
    padding-left: 47px;
    height: 30px;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #b5cbf4 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    
    @include xs {
        padding-right: 47px;
        text-align: center;
    }

    &:hover, &:active, &:focus {
        color: #56e242;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #fff;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: #fff;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: #fff;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: #fff;
    }

    &:hover, &:active, &:focus {
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #56e242;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #56e242;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: #56e242;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: #56e242;
        }
    }

    &.has-error {
        border-bottom: 1px solid #d46262 !important;
    }
}

.b_contact__form_group {
}

.b_contact__label {
}

.b_contact__msg {
    margin-bottom: 25px;
}