$b_header__row--top_DEFAULT_HEIGHT: 122px !default;
$b_header__row--top_TABLET_HEIGHT: 97px !default;
$b_header__row--top_PHONE_HEIGHT: 67px !default;
$b_header__row--top_DEFAULT_HEIGHT--small: 90px !default;

.b_logo {
    &--footer {
        width: 270px;
        height: 50px;
        @include md {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }
}

.b_logo__link {
    display: block;
    height: 100%;
    max-width: 100%;
}

.b_logo__image {
    display: block;
    max-height: 100%;
    max-width: 100%;
    transition: all .2s ease;

    .b_logo--header & {
        max-width: 100%;
        max-height: $b_header__row--top_DEFAULT_HEIGHT * .8;

        @include md {
            max-height: $b_header__row--top_TABLET_HEIGHT * .8;
        }

        @include xs {
            max-height: $b_header__row--top_PHONE_HEIGHT * .8;
        }

        .b_header__row--small & {
            max-height: $b_header__row--top_DEFAULT_HEIGHT--small * .8;
        }
    }
    .b_header--small & {
    }

    &--footer {
        max-width: 100%;
        max-height: 100%;
        @include md {
            margin-left: auto;
            margin-right: auto;
        }
    }
}