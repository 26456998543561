.b_testimonial_slider {
    @include padding;
}

.b_testimonial_slider__img {
    text-align: center;
    img {
        @include img_border(#6d9df7);
        max-width: 100%;
    }
    @include sm {
        margin: 0 0 40px;
    }
}

.b_testimonial_slider__row {
    @include smu {
        display: flex;
        align-items: center;
    }
}

.b_testimonial_slider__content {
    @extend %b_common_p;
    text-align: center;
}

.b_testimonial_slider__title {
    @extend %home_page_header;
    @extend %bottom_border_thin;

    @include sm {
        margin-top: 0;
    }

}

.b_testimonial_slider__quote {
    &--left {
    }
    &--right {
    }
}

.b_testimonial_slider__signature {
    display: none;
}

.b_testimonial_slider__row {
    @include sm {
        display: flex;
        flex-direction: column-reverse;
    }
}