.swiper-container-wrapper {
    position: relative;
    &--testimonial {
    }
    &--staff {
    }
    &--map {
    }
    &--photo {
    }
    &--video {
    }
    &--proflogo {
    }
}

.swiper-container {
    position: initial;
    &--main_slider {
        position: relative;
    }
    &--testimonial {
    }
    &--staff {
    }
    &--map {
    }
    &--photo {
    }
    &--video {
    }
    &--proflogo {
    }
}

.swiper-wrapper {
    margin-bottom: 25px;

    &--main_slider {
        margin-bottom: 0;
    }
    &--testimonial {
    }
    &--staff {
    }
    &--map {
    }
    &--photo {
        align-items: center;
    }
    &--video {
    }
    &--proflogo {
    }
}

.swiper-pagination-container {
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
    z-index: 10;
}

.swiper-container-horizontal {
    .swiper-pagination {
        bottom: 0px;
        position: static;
        margin-bottom: 25px;

        @include xs {
            padding: 0 85px;
        }

        &--main_slider {
            position: absolute;
            position: initial;
            background: #fff;
            padding: 3px 8px 1px;
            display: inline-block;
            width: auto;
            border-radius: 15px;

        }
        &--testimonial {
        }

        &--staff {
        }
        &--map {
        }
        &--photo {

        }
        &--video {
        }
        &--proflogo {
        }
    }
}

.swiper-pagination-bullet {
    opacity: 1;
    background-color: $color-secondary;
    width: 12px;
    height: 12px;
    margin: 5px;
    transition: background .2s ease;

    &-active {
        background: $color-primary;
    }

    &:hover, &:active, &:focus {
        background: $color-primary;
    }

    #staffSlider & {
        &-active {
            background: #fff;
        }

        &:hover, &:active, &:focus {
            background: #fff;
        }
    }
}

.swiper-button-prev {
    @include timg('swiper_prev.png');
    width: 27px;
    height: 49px;
    left: -80px;
    transition: filter .2s ease;

    @include md {
        left: -35px;
    }

    &:hover, &:active, &:focus {
        filter: invert(100%);
    }

    &--main_slider {
        display: none;
    }
    &--testimonial {
    }
    &--staff {
        @include sm {
            left: -15px;
        }
        @include xs {
            left: -10px;
        }
    }
    &--map {
        @include timg('swiper_prev_dark.png');

        &:hover, &:active, &:focus {
            filter: invert(100%);
        }

        @include sm {
            left: 15px;
            bottom: 15px;
            top: auto;
        }
    }
    &--photo {
        @include timg('swiper_prev_dark.png');

        @include sm {
            left: 5px;
            top: auto;
            bottom: 0;
        }

    }
    &--video {
    }
    &--proflogo {
    }
}

.swiper-button-next {
    @include timg('swiper_next.png');
    width: 27px;
    height: 49px;
    right: -80px;
    transition: filter .2s ease;

    @include md {
        right: -35px;
    }

    &:hover, &:active, &:focus {
        filter: invert(100%);
    }

    &--main_slider {
        display: none;
    }

    &--testimonial {
    }

    &--staff {
        @include sm {
            right: -15px;
        }
        @include xs {
            right: -10px;
        }
    }
    &--map {
        @include timg('swiper_next_dark.png');

        &:hover, &:active, &:focus {
            filter: invert(100%);
        }

        @include sm {
            right: 15px;
            bottom: 15px;
            top: auto;
        }
    }
    &--photo {
        @include timg('swiper_next_dark.png');

        @include sm {
            right: 5px;
            top: auto;
            bottom: 0;
        }

    }
    &--video {
    }
    &--proflogo {
    }
}

.swiper-slide {
    height: auto;

    &--main_slider {
        background-position: center;
        background-size: cover;
        height: 792px;
        @include sm {
            height: 600px;
        }
        @include xs {
            padding: 0;
            text-align: center;
        }
        @include xs {
            height: 470px;
            &:after {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, .7);
                z-index: -1;
            }
        }
        &[data-anchor="slider--slide1"] {
            @include sm {
                background-position: 46% 0;
            }
            @include xs {
                background-position: 54% 0;
            }
        }
        &[data-anchor="slider--slide2"] {
            @include sm {
                background-position: 46% 0;
            }
            @include xs {
                background-position: 62% 0;
                &:after {
                    background: rgba(0, 0, 0, .7);
                }
            }
        }
        &[data-anchor="slider--slide3"] {
            @include xs {
                background-position: 62% 0;
            }
        }
        &[data-anchor="slider--slide4"] {
            @include xs {
                background-position: 65% 0;
                &:after {
                    background: rgba(0, 0, 0, .7);
                }
            }
        }
    }
    &--testimonial {
    }
    &--staff {
        height: initial;
    }
    &--map {
        padding: 20px 45px;

        @include xs {
            padding: 40px;
        }
    }
    &--photo {
        padding: 0;
    }
    &--video {
    }
    &--proflogo {
    }
}

.all-slides-visible {
    display: none;
}

.swiper-button-disabled {
    display: none;
}