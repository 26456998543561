.b_main_nav {
    &--header {}
    &--mmenu {}
    &--footer {}
}

.b_main_nav__ul {
    &--header {
        margin: 0;
        display: flex;
        justify-content: flex-start;
    }
    &--mmenu {
        @include xsmm {
            min-height: 20px;
            overflow-y: scroll;
        }
    }
    &--footer {
        margin: 0;
        display: flex;
        justify-content: flex-end;
    }
    ul {
        display: none;
    }
}

.b_main_nav__item {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    &--active {}
    &--header {
        margin-right: 75px;
    }
    &--footer {
        margin-right: 35px;
    }

    &--header, &--footer {
        background-image: none;
    }
    &--mmenu {
        background-image: none;

    }
}

.b_main_nav__link {
    display: block;
    position: relative;
    &:hover,
    &:focus {}
    &--active {}
    &--header, &--footer {
        font-family: 'montserratregular';
        font-size: 15px;
        line-height: 75px;
        color: #fff;
        position: relative;
        transition: color .2s ease;

        &:after {
            content: '';
            position: absolute;
            display: block;
            height: 8px;
            width: 100%;
            top: 100%;
            left: 0;
            background-color: transparent;
            transition: background-color .2s ease;
            z-index: 999;
        }

        &:hover,
        &:active,
        &:focus,
        &.b_main_nav__link--active {
            color: #fff;
            text-decoration: none;
            &:after {
                background-color: $color-secondary;
            }
        }

        .b_header--small & {
            line-height: 50px;
        }
    }
    &--mmenu {
        @include md {
            padding: 6px 10px 6px 20px !important;
        }
        @include xsmm {
            padding: 4px 10px 4px 20px !important;
            font-size: 13px;
        }
    }
    &--footer {
        line-height: 35px;
        &:after {
            height: 3px;

        }
    }
}
