%bottom_border {
    &:after {
        content: '';
        display: block;
        width: 72px;
        height: 4px;
        background-color: #6b9cf7;
        margin-top: 20px;
    }
}

%bottom_border_thin {
    &:after {
        content: '';
        display: block;
        width: 77px;
        height: 1px;
        margin: 20px auto 0;
        background-color: #eab82c;
    }
}

%home_page_header {
    font-family: 'open_sanssemibold';
    font-size: 24px;
    line-height: 1.7em;
    color: #000000;
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    letter-spacing: 3px;
}