@font-face {
    font-family: 'montserratbold';
    src: url('montserrat/montserrat_700.woff2') format('woff2'), url('montserrat/montserrat_700.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratregular';
    src: url('montserrat/montserrat_regular.woff2') format('woff2'), url('montserrat/montserrat_regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanslight';
    src: url('opensans/open_sans_300.woff2') format('woff2'), url('opensans/open_sans_300.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanslight_italic';
    src: url('opensans/open_sans_300italic.woff2') format('woff2'), url('opensans/open_sans_300italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanssemibold';
    src: url('opensans/open_sans_600.woff2') format('woff2'), url('opensans/open_sans_600.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sanssemibold_italic';
    src: url('opensans/open_sans_600italic.woff2') format('woff2'), url('opensans/open_sans_600italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansbold';
    src: url('opensans/open_sans_700.woff2') format('woff2'), url('opensans/open_sans_700.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansbold_italic';
    src: url('opensans/open_sans_700italic.woff2') format('woff2'), url('opensans/open_sans_700italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansextrabold';
    src: url('opensans/open_sans_800.woff2') format('woff2'), url('opensans/open_sans_800.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansextrabold_italic';
    src: url('opensans/open_sans_800italic.woff2') format('woff2'), url('opensans/open_sans_800italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansitalic';
    src: url('opensans/open_sans_italic.woff2') format('woff2'), url('opensans/open_sans_italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansregular';
    src: url('opensans/open_sans_regular.woff2') format('woff2'), url('opensans/open_sans_regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dancingscript_regular';
    src: url('dancing/Dancing_Script_regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dancingscript_bold';
    src: url('dancing/Dancing_Script_700.ttf');
    font-weight: normal;
    font-style: normal;
}
