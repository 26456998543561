$b_header__row--top_DEFAULT_HEIGHT: 122px !default;
$b_header__row--bottom_DEFAULT_HEIGHT: 75px !default;
$b_header__row--top_TABLET_HEIGHT: 97px !default;
$b_header__row--top_PHONE_HEIGHT: 67px !default;
$b_header__row--top_DEFAULT_HEIGHT--small: 90px !default;
$b_header__row--bottom_DEFAULT_HEIGHT--small: 50px !default;
.b_header {
    position: fixed;
    top: 0;
    background: #eee;
    width: 100%;
    z-index: 12;
    &--small {
        background: #aaa;
    }
}

.b_header__section {
    &--top {
        background-color: #fff;
    }
    &--bottom {
        background-color: $color-primary;
        @include md {
            display: none;
        }
        .index & {
            position: relative;
            &:after {
                content: '';
                display: block;
                height: 8px;
                width: 100%;
                position: absolute;
                top: auto;
                left: 0;
                background: #fff;
            }
        }
    }
}

.b_header__row {
    &--top {
        display: flex;
        height: $b_header__row--top_DEFAULT_HEIGHT;
        align-items: center;
        transition: height .2s ease, padding .2s ease;
        @include md {
            height: $b_header__row--top_TABLET_HEIGHT;
            padding: 0;
            align-items: center;
        }
        @include xs {
            height: $b_header__row--top_PHONE_HEIGHT;
        }
        &.b_header__row--small {
            padding: 0;
            height: $b_header__row--top_DEFAULT_HEIGHT--small;
        }
    }
    &--bottom {
        display: flex;
        height: $b_header__row--bottom_DEFAULT_HEIGHT;
        align-items: center;
        &.b_header__row--small {
            padding: 0;
            height: $b_header__row--bottom_DEFAULT_HEIGHT--small;
        }
    }
}

.b_header__top_col {
    &--left {}
    &--right {
        text-align: right;
    }
    &--mmenu_btn {}
}

.b_header__address {
    font-family: 'open_sansregular';
    font-size: 14px;
    line-height: 1.7em;
}

.b_header__bottom_col {
    &--right {
        align-self: center;
    }
}

.b_header__info_section {
    display: flex;
    align-items: center;
}

.b_header__info_section_cell {
    &--left {
        flex-grow: 1;
        @include md {
            padding-right: 25px;
        }
        @include xs {
            display: none;
        }
    }
    &--right {
        @include lg {
            display: none;
        }
        @include xs {
            flex-grow: 1;
        }
    }
}
