$color-primary: #131b2e !default;
$color-text-primary: #868d90 !default;

/*
* ---------------- H1
*/
//
//body {
//    font-family: 'open_sansregular';
//    font-size: 14px;
//    color: #837e85;
//}

%b_common_h1 {
    font-family: 'montserratregular';
    color: $color-primary;
    font-size: 24px;
    line-height: 50px;
    margin-bottom: 25px;
    margin-top: 30px;
    clear: both;
    @include xs {
        font-size: 28px;
        line-height: 36px;
    }
}

h1 {
    @extend %b_common_h1;
}


/*
* ---------------- H2
*/

%b_common_h2 {
    color: #000000;
    font-size: 18px;
    line-height: 1.7em;
    margin-bottom: 25px;
    margin-top: 30px;
    clear: both;
    @include xs {}
}

h2 {
    @extend %b_common_h2;
}


/*
* ---------------- H3
*/

%b_common_h3 {
    color: #000000;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 25px;
    margin-top: 30px;
    clear: both;
}

h3 {
    @extend %b_common_h3;
}


/*
* ---------------- H4, h5, h6
*/

%b_common_h4 {
    color: $color-primary;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    margin-top: 20px;
    clear: both;
}

h4,
h5,
h6 {
    @extend %b_common_h4;
}


/*
* ---------------- P
*/

%b_common_p {
    color: $color-text-primary;
    font-family: 'open_sansregular';
    font-size: 14px;
    line-height: 1.7em;
    margin-bottom: 20px;
}

p {
    @extend %b_common_p;
}

.txt {
    &--center {
        text-align: center;
    }
}


/*
* ---------------- ul, ol
*/

%b_common_ul {
    margin-bottom: 20px;
}

%b_common_ol {
    list-style-type: decimal;
    padding-left: 20px;
    margin-bottom: 20px;
}

ul {
    @extend %b_common_ul;
}

ol {
    @extend %b_common_ol;
}


/*
* ---------------- ul li
*/

%b_common_ul_li {
    @include timg('b_common_li_bg.png');
    background-position: left 4px;
    color: $color-text-primary;
    font-size: 14px;
    line-height: 1.5em;
    padding-left: 30px;
    margin-left: 20px;
}

ul li {
    @extend %b_common_ul_li;
}


/*
* ---------------- ol li
*/

%b_common_ol_li {
    color: $color-text-primary;
    font-size: 14px;
    line-height: 1.5em;
    padding-left: 0;
}

ol li {
    @extend %b_common_ol_li;
}


/*
* ---------------- img
*/

img {
    // Client Editables
    .b_address__head > & {
        max-width: 100%;
        max-height: 85px;
    }
}

img.img_content_left {
    float: left;
    margin: 0 25px 25px 0;
    max-width: 100%;
    @include xs {
        float: none;
        display: block;
        margin: 25px auto;
    }
}

img.img_content_right {
    float: right;
    margin: 0 0 25px 25px;
    max-width: 100%;
    @include xs {
        float: none;
        display: block;
        margin: 25px auto;
    }
}

img.img_content_center {
    display: block;
    margin: 35px auto;
    max-width: 100%;
    @include xs {
        float: none;
        display: block;
        margin: 25px auto;
    }
}
