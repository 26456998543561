.b_map {
    background: #eee;
    @include padding(140);
}

.b_map__wrapper {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;

}

.b_map__gmap {
    height: 500px;
    @include xs {
        height: 250px;
    }
}

.b_map__title {
    @extend %home_page_header;
    @extend %bottom_border_thin;

    margin-top: 0;
    margin-bottom: 50px;

    &:after {
        background-color: #eab92e;
    }
}

.b_map__view_all {
}

.b_map__navigation {
    @include sm {
        text-align: center;
    }
}

.b_map__marker {
    @include timg('b_map__marker.png');
}

.b_map__get_directions {
    @include timg('b_map__get_directions.png');
}

.b_map__marker, .b_map__get_directions {
    background-position: center;
    background-color: #f6f6f6;
    text-indent: -9999px;
    display: block;
    width: 63px;
    height: 63px;
    float: right;
    margin-right: 40px;

    &:hover, &:active, &:focus {
        filter: hue-rotate(200deg);
    }

    @include sm {
        float: none;
        display: inline-block;
        margin: 15px;
    }
}

.b_map__location {
    @include timg('b_map__location__bg.png');
    font-family: 'open_sansbold';
    line-height: 26px;
}

.b_map__hours {
    @include timg('b_map__hours__bg.png');
}

.b_map__contacts {
    @include timg('b_map__contacts__bg.png');
}

.b_map__location, .b_map__contacts, .b_map__hours {
    padding-left: 55px;
    font-size: 14px;
    line-height: 1.3em;
    margin-bottom: 35px;
    color: #000000;

    @include sm {
        padding: 0;
        text-align: center;
        background: none;
    }

    &:hover, &:active, &:focus {
        .b_map__location_inner, .b_map__contacts_inner, .b_map__hours_inner {
            border-left: 3px solid #6391e7;
        }
    }
}

.b_map__location_inner, .b_map__contacts_inner, .b_map__hours_inner {
    padding-left: 12px;
    border-left: 1px solid #dde0e2;
    transition: border .1s ease;

    @include sm {
        border: none !important;
        padding-left: 0;
    }
}

.b_map__hours {

    // Nesting because dynamic tags.
    table {
        width: 100%;
    }

    td:first-child {
        padding-right: 10px;
        color: #000;
    }

    td {
        color: #837e85;
        padding: 5px 5px 5px 0;
        line-height: 1.3em;

        @include sm {
            display: block;
            width: 100%;
        }
    }

    p, li, div {
        font-family: 'open_sansregular';
        color: #837e85;
        font-size: 14px;
        line-height: 1.3em;
    }
}

.b_map__fax_label, .b_map__contacts_phone_label {
    font-family: 'open_sansbold';
    color: #000;
    margin-right: 6px;
}

.b_map__fax {
    color: #000;
}

.b_map__location, .b_map__contacts, .b_map__hours, .b_map__hours td, .b_map__hours p, .b_map__hours li, .b_map__hours div, .b_map__contacts_fax_label, .b_map__contacts_phone_label {
    @include sm {
        font-size: 20px;
    }

    @include xs {
        font-size: 16px;
    }
}