.b_main_slider {
    &__text {}
}

.b_main_slider__content {
    padding-top: 105px;
    @include xs {
        padding-top: 30px;
    }
    .swiper-slide--main_slider[data-anchor="slider--slide3"] & {
        padding-top: 230px;
        @include xs {
            padding-top: 110px;
        }
    }
    .swiper-slide--main_slider[data-anchor="slider--slide4"] & {
        padding-top: 195px;
        @include md {
            padding-top: 245px;
        }
        @include sm {
            padding-top: 165px;
        }
        @include xs {
            padding-top: 30px;
        }
    }
}

.b_main_slider__slide {
    background-position: center;
}

.b_main_slider__title {
    font-size: 24px;
    line-height: 27px;
    color: #6b9cf7;
    margin-bottom: 40px;
}

.b_main_slider__text {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 15px;
}

.b_main_slider__span {
    font-family: 'dancingscript_bold';
    color: #000;
    display: block;
    font-size: 94px;
    line-height: .85;
    @include md {
        font-size: 76px;
    }
    @include xs {
        font-size: 52px;
    }
    &--brown {
        color: $color-primary;
    }
    &--orange {
        color: $color-secondary;
    }
    &--white {
        color: #fff;
    }
    &--small {
        font-size: 44px;
        line-height: 1.1;
        @include md {
            font-size: 30px;
        }
    }
}

// Animation
.swiper-slide-active .b_main_slider__content * {
    position: relative;
}

@for $i from 1 through 20 {
    .swiper-slide-active .b_main_slider__content *:nth-of-type(#{$i}) {
        animation: slider (.9s + .25 * $i) ease;
    }
}

.swiper-slide-active .b_main_slider__content span {}

@keyframes slider {
    0% {
        opacity: 0;
        left: 500px;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}
