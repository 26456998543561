.b_footer {
    @include timg('b_footer_bg.jpg');
    background-size: cover;
    background-position: center;
    @include padding(120);
}

.b_footer__row {
    &--top {
        @include smu {
            margin-bottom: 20px;
        }
    }
    &--middle {
        margin: 40px auto;
        @include sm {
            text-align: center;
        }
    }
}

.b_footer__top_section {
    display: flex;
    align-items: center;

    @include sm {
        flex-direction: column;
        justify-content: center;
    }
}

.b_footer__cell {
    &--logo {
        margin-right: 20px;

        @include sm {
            margin-bottom: 25px;
        }
    }
    &--menu {
        flex-grow: 1;

        @include sm {
            display: none;
        }
    }
}

.b_footer__nav {
    background: #000;
    @include padding;
}

.b_footer__content {
    @include padding;
}

.b_footer__wtm {
    background: #000;
    text-align: center;
    color: #fff;
    @include padding;
}

.b_footer__wtm_logo {
}

.b_footer__col_header {
    font-family: 'open_sansbold';
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    font-weight: normal;
}

.b_footer__col_para {
    font-family: 'open_sansregular';
    font-size: 14px;
    line-height: 22px;
    color: #dfe1e7;
}

.b_footer__disclaimer {
    // CLient Editable
    p, li {
        font-family: 'open_sansregular';
        font-size: 14px;
        line-height: 22px;
        color: #dfe1e7 !important;

        strong {
            font-family: 'open_sansbold';
            font-size: 14px;
            line-height: 22px;
            color: #fff;
            font-weight: normal;
        }
    }

}

.b_footer__phone {
    color: #fff;
    font-family: 'open_sansbold';
    &:hover, &:active, &:focus {
        color: #fff;
    }
}

.b_wtm_footer {
    background: #3b5688;
    padding: 40px 0px;
}
.b_wtm_footer__row {
    &--top {
        text-align: center;
        p, a {
            font-family: 'open_sansregular';
            font-size: 14px;
            line-height: 22px;
            color: #dfe1e7 !important;
        }
    }
}
.b_wtm_footer__wtm_logo {
    margin-bottom: 10px;
}
