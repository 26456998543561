.b_video_gallery {
    @include timg('b_video_gallery_bg.jpg');
    background-position: center;
    background-size: cover;
    @include padding;
}

.b_video_gallery__title {
    @extend %home_page_header;
    @extend %bottom_border_thin;
    color: #fff;
    margin-top: 0;
    margin-bottom: 60px;

    &:after {
        background-color: #fff;
    }
}

.b_video_gallery__wrapper {
    width: 642px;
    max-width: 100%;
    margin: 0 auto;
}

.b_video_gallery__video_container {
    position: relative;
    cursor: pointer;
}

.b_video_gallery__play_btn {
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;
    @include timg('play.png');
    background-position: center;
}
