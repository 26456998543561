$b_header--height--top: 100px !default;
$b_header--height--bottom: 54px !default;
$b_header--height--top--small: 60px !default;

.b_staff_subpage {}

.b_staff_subpage__title {
    @extend %b_common_h1;
}

.b_staff_subpage__name {
    margin-top: 0;
}

.b_staff_subpage__pic_wrapper {
    @include sm {
        text-align: center;
        margin-bottom: 25px;
    }
}

.b_staff_subpage__headshot {
    max-width: 100%;
}

.b_staff_subpage__anchor {
    position: relative;
    top: (($b_header--height--top + $b_header--height--bottom) - 25) * -1;
}
