.b_share {
    text-align: right;
    &--b_footer {
        margin-bottom: 40px;
        @include xs {
            text-align: center;
        }
    }
}

.share-facebook {
    .b_share--b_footer & {
        width: 115px;
    }
}

.share-twitter {
    .b_share--b_footer & {
        width: 60px !important;
    }
}

#___plus_0 {
    .b_share--b_footer & {
        width: 57px !important;
        overflow: hidden !important;
    }
}

