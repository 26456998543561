.b_photo_gallery {
    background: #eee;
    @include padding(140);
}

.b_photo_gallery__title {
    @extend %home_page_header;
    @extend %bottom_border_thin;
    margin-top: 0;
}

.b_photo_gallery__slide {
    border: 24px solid #6b9af2;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
}

.b_photo_gallery__slide_inner {
    border: 3px solid #fff;
    max-height: 349px;
    overflow: hidden;
    width: 349px;
    text-align: center;
    max-width: 100%;
}

.b_photo_gallery__link {
}

.b_photo_gallery__img {
    max-width: 100%;
}

