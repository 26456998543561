// HTML Markup
// <div class="b_button b_button--center"><a href="example.html" class="b_button__btn">View All</a></div>
.b_button {
    text-align: left;
    &--center {
        text-align: center;
    }
    &--main_slider {
        margin-top: 50px;
        @include xs {
            text-align: center;
        }
    }
    &--margin_top {
        margin-top: 35px;
    }
}

.b_button__btn {
    font-size: 16px;
    font-family: 'montserratbold';
    color: #fff;
    background: $color-secondary;
    position: relative;
    padding: 16px 45px 13px;
    top: 0;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 5px solid #eca53d;
    transition: background 2s;
    overflow: hidden;
    z-index: 1;

    &:focus,
    &:hover,
    &:active {
        color: #fff;
        text-decoration: none;
        background: transparent;
        &:before {
            opacity: 1;
            transform: rotate(90deg);
            top: -445px;
            left: 100%;
        }
        &:after {
            opacity: 1;
            transform: rotate(90deg);
            bottom: -445px;
            right: 100%;
        }
    }
    &:before,
    &:after {
        display: block;
        content: '';
        height: 600px;
        width: 400px;
        position: absolute;
        left: -290px;
        top: -540px;
        background: $color-primary;
        transition: top 1s, bottom 1s, left 1s, right 1s, transform 1s, opacity 1.5s;
        transform: rotate(45deg);
        opacity: .5;
        z-index: -1;
    }
    &:after {
        left: initial;
        right: -290px;
        top: initial;
        bottom: -540px;
    }
    &--alt_hover_color {
        &:before, &:after {
            background: green;
        }
    }
}
