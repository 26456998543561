.b_welcome_section {
    @include padding;

    @include xs {
        padding-top: 0;
    }
}

.b_welcome_section__row {
    display: flex;
    align-items: center;

    @include sm {
        flex-direction: column-reverse;
    }
}
.b_welcome_section__col {

}

.b_welcome_section__img_wrapper {
    text-align: center;
    img {
        max-width: 100%;
        @include img_border;
    }
    @include sm {
        margin: 40px 0 0;
    }
}

.b_welcome_section__text {

    @include smu {
        width: 400px;
        max-width: 100%;
        margin: 0 auto;
    }

    // Client Editable

    h1, h2, h3, h4, h5, h6 {
        @extend %home_page_header;
        @extend %bottom_border_thin;
    }

    p {
        color: #837e85;
        line-height: 31px;
    }
}

