.b_staff_slider {
    @include timg('b_staff_slider__bg.jpg');
    background-position: center;
    background-size: cover;
    @include padding(140);
}

.b_staff__slide {
    background-color: #fff;
    padding: 12px;
    height: 100%;
    display: block;
    margin: 0 auto;
    box-shadow: 0px 10px 16px 0px rgba(0,0,0,0.1);
    width: 286px;
    max-width: 100%;
}

.b_staff_slider__title {
    @extend %home_page_header;
    @extend %bottom_border_thin;
    color: #fff;
    margin-top: 0;

    &:after {
        background-color: #fff;
    }
}

.b_staff_slider__img {
    text-align: center;
    height: 229px;
    overflow: hidden;
}

.b_staff_slider__img_el {
    max-width: 100%;
}

.b_staff_slider__link {
}

.b_staff_slider__content {
    text-align: center;
    padding: 35px 0;
}

.b_staff__name {
    @extend %bottom_border_thin;
    margin-top: 0;
    font-family: 'open_sansregular';
    font-size: 24px;
    line-height: 31px;
    color: #343742;
    margin-bottom: 15px;

    &:after {
        background-color: #6d9df7;
        margin-top: 12px;
    }
}

.b_staff__desc {
    font-family: 'open_sansitalic';
    font-size: 15px;
    line-height: 21px;
    color: #343742;
}